//前端自己的枚举  会和后端枚举合并 如果命名和后端枚举重复会以后端枚举优先并覆盖前端枚举
export default {
    sex: [
        {
            label: "女",
            value: 0
        },
        {
            label: "男",
            value: 1
        }
    ],
}