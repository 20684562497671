import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import enumObj from '../utils/enum.js'
import { Message } from 'element-ui'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		showMenu: false, //是否展开侧边导航栏
		pageTab: [], //标题栏列表
		tabIndex: '/home', //标题栏当前选项
		enum: {}, //全局枚举 从后台获取
		permissList: [
			// 'system-admin-manage','category-admin-manage','inventory-admin-manage','order-admin-manage','shop-admin-manage','rider-admin-manage',"user-admin-manage","home", 'login', 'info', 'user', 'rider', 'order', 'orderList', 'goods', 'category', 'goodsCategory', 'franchisee', 'agent', 'waste', 'setUp', 'reward','orderDetails','inventory','inventoryList',"inventoryOutIn","inventoryDetails","getAllInventoryLedger","getInventoryLedger","userWithdrawal",'power','shop','role'
		], //权限列表 从后台获取
		theme: ''
	},
	getters: {
		getEnumData(state) {
			return state.enum
		}
	},
	mutations: {
		//打开侧边导航栏
		openShowMenu(state) {
			state.showMenu = true
		},
		//关闭侧边导航栏
		closeShowMenu(state) {
			state.showMenu = false
		},
		//关闭侧边导航栏
		setTheme(state, theme) {
			state.theme = theme
		},
		//新增标题tab选项卡
		addPageTab(state, data) {
			if (!state.pageTab.map(e => e.path).includes(data.path)) {
				state.pageTab.push(data)
			} else if (!state.pageTab.map(e => e.fullPath).includes(data.fullPath)) {
				state.pageTab.map((e, i) => {
					if (e.path == data.path) {
						state.pageTab[i] = data
						state.tabIndex = e.fullPath
					}
				})
			}
		},
		//移除标题tab选项卡
		removePageTab(state, data) {
			if (state.pageTab.length > 1) {
				state.pageTab = state.pageTab.filter(e => e.fullPath !== data)
				if (!state.pageTab.map(e => e.fullPath).includes(state.tabIndex)) {
					router.push(state.pageTab[state.pageTab.length - 1].fullPath)
				}
			} else {
				Message.closeAll()
				Message({
					type: 'error',
					message: '仅剩一个!'
				})
			}
		},
		//设置当前标题tab选项卡当前选项
		setTabIndex(state, data) {
			state.tabIndex = data
		},
		//获取枚举列表
		getEnum(state, data) {
			state.enum = Object.assign(enumObj, data)
		},
		//获取权限列表
		getPermissList(state, data) {
			state.permissList = data
		}
	},
	actions: {},
	modules: {}
})
