<template>
	<div class="sidebar">
		<div class="side">
			<div class="left" @click="showMenu">
				<i :class="!$store.state.showMenu ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
			</div>
			<div class="sys-title">
				<!-- <viewImage local="assets/image/home_logo1.png" width="30px" height="30px" margin="0 10px 0 0" /> -->
				<div class="title">{{ $t('common.title') }}</div>
			</div>
			<div class="right">
				<el-dropdown>
					<div class="mine">
						<svg-icon icon-class="theme" font-size="20px" margin="0 5px 0 0"></svg-icon>
						{{ $t('common.themeSwitch') }}
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item :disabled="theme == ''" @click.native="switchTheme(0)">
							{{ $t('common.deepBlue') }}
						</el-dropdown-item>
						<el-dropdown-item :disabled="theme == '0'" @click.native="switchTheme('0')">
							{{ $t('common.classic') }}
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<el-dropdown v-if="sessionInfo.userInfo.username">
					<div class="mine">
						<svg-icon icon-class="admin" color="#fff" font-size="20px" margin="0 5px 0 0"></svg-icon>
						{{ sessionInfo.userInfo.username }}
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item @click.native="loginOut">
							{{ $t('common.out') + $t('common.in') }}
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
	</div>
</template>

<script>
// import viewImage from '@/components/viewImage.vue'
import svgIcon from '@/components/svgIcon.vue'
export default {
	name: 'sideBar',
	components: {
		// viewImage,
		svgIcon
	},
	props: {},
	data() {
		return {
			sessionInfo: {
				userInfo: {
					username: ''
				}
			},
			theme: ''
		}
	},
	mounted() {
		let sessionInfo = localStorage.getItem('sessionInfo')
		if (sessionInfo) {
			this.sessionInfo = JSON.parse(sessionInfo)
		}
		if (localStorage.getItem('theme')) {
			this.theme = localStorage.getItem('theme')
			this.switchTheme(localStorage.getItem('theme'))
			this.$store.commit('setTheme', this.theme)
		}
	},
	methods: {
		loginOut() {
			localStorage.removeItem('sessionInfo')
			this.$router.push('/')
		},
		showMenu() {
			if (this.$store.state.showMenu) {
				console.log('closeShowMenu----- ')
				this.$store.commit('closeShowMenu')
			} else {
				console.log('openShowMenu----- ')
				this.$store.commit('openShowMenu')
			}
		},
		switchTheme(e) {
			this.theme = e || ''
			localStorage.setItem('theme', e || '')
			window.document.documentElement.setAttribute('data-theme', `theme${e || ''}`)
			this.$store.commit('setTheme', e || '')
		}
	}
}
</script>

<style lang="scss" scoped>
@import '../assets/css/global.scss';
.sidebar {
	.side {
		width: 100%;
		height: 60px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 10px;
		@include bg_color(#2998e8);
		position: fixed;
		top: 0;
		left: 0;
		.side-item {
			display: flex;
			align-items: center;
			height: 60px;
			font-size: 14px;
			font-weight: 500;
			color: #ffffff;
			h1 {
				font-size: 24px;
				font-weight: 500;
			}
			& .title {
				font-size: 20px;
				font-family: Source Han Sans CN;
				font-weight: bold;
				color: #ffffff;
			}
		}
	}
}
.left {
	line-height: 30px;
	font-size: 30px;
	margin-left: 8px;
	color: #ffffff;
}
.sys-title {
	margin: 0 auto;
	@include sideBar_title(#ffffff);
	display: flex;
	.title {
		line-height: 30px;
		font-size: 24px;
		font-weight: 500;
	}
}
.el-dropdown {
	@include s_font_color(#ffffff);
}
.mine {
	color: #fff;
	cursor: pointer;
	display: flex;
	align-items: center;
	padding-left: 20px;
}
</style>
