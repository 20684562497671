<template>
	<el-form
		class="main_header"
		:inline="true"
		:model="formModel"
		size="mini"
		slot="form"
		ref="commonForm"
		@keyup.enter.native="query()"
	>
		<el-tabs v-if="tabs.length > 0" v-model="activeTabsName" @tab-click="tabsClick">
			<el-tab-pane
				v-for="(t, k) in tabs"
				:key="k"
				:label="t[tabsProps.label || 'label']"
				:name="t[tabsProps.name || 'name']"
			></el-tab-pane>
		</el-tabs>
		<div class="main_row">
			<div class="form_container">
				<slot name="form"></slot>
				<el-form-item>
					<div class="common_btn" @click="query" v-if="showBtn">{{ $t('common.query') }}</div>
					<slot name="btn"></slot>
					<div class="common_btn tint" @click="reset" v-if="showBtn">{{ $t('common.reset') }}</div>
					<div class="common_btn tint" @click="switchShowMore" v-if="$slots.more">
						<span>{{ $t(`${showMore ? 'common.closeScreen' : 'common.openScreen'}`) }}</span>
						<svg-icon icon-class="arrow_1"></svg-icon>
					</div>
				</el-form-item>
			</div>
			<div class="tools_container">
				<slot name="tools"></slot>
			</div>
		</div>
		<el-collapse-transition>
			<div v-show="showMore">
				<div class="more_container">
					<slot name="more"></slot>
				</div>
				<div class="pt16"></div>
			</div>
		</el-collapse-transition>
	</el-form>
</template>

<script>
import ToolsBtn from './toolsBtn'
import ToolsDropdown from './toolsDropdown'
export default {
	name: 'CommonHeader',
	props: {
		formModel: Object, //传入form的数据
		defaultTab: String, //选中的tab值
		special: String, //保留某个值不重置，其余重置
		showBtn: {
			//是否显示搜索和重置按钮
			type: Boolean,
			default: true
		},
		tabsProps: {
			//指定tab数据的格式
			type: Object,
			default: () => ({
				label: 'label',
				name: 'name'
			})
		},
		tabs: {
			//tab的数据
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			showMore: false,
			activeTabsName: ''
		}
	},
	/* eslint-disable*/
	components: {
		ToolsBtn,
		ToolsDropdown
	},
	/* eslint-enable */
	mounted() {
		this.activeTabsName = this.defaultTab
	},
	methods: {
		switchShowMore() {
			this.showMore = !this.showMore
		},
		reset() {
			if (this.special) {
				let str = this.formModel[this.special]
				const form = this.$refs.commonForm
				if (form) {
					form.resetFields()
				}
				let obj = {}
				obj[this.special] = str
				Object.assign(this.formModel, obj)
			} else {
				const form = this.$refs.commonForm
				if (form) {
					form.resetFields()
				}
			}
			this.$emit('resetTime')
			this.$nextTick(() => {
				this.$emit('query')
			})
		},
		query() {
			this.$emit('query')
		},
		other() {
			this.$emit('other')
		},
		tabsClick(tab) {
			this.$emit('tabsChange', tab.name)
		}
	}
}
</script>

<style lang="scss" scoped>
.main_header {
	display: block;
}
.pt16 {
	padding-bottom: 16px;
}

.el-form {
	user-select: none;
}
::v-deep .el-tabs__item {
	font-size: 16px;
	line-height: 46px;
	height: 46px;
	padding: 0 30px;
}
::v-deep .el-tabs__nav-wrap {
	overflow: inherit;
	margin-bottom: -1px;
	position: relative;
	.el-tabs__active-bar {
		height: 4px;
		border-radius: 1px;
	}
	&::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: calc(100% + 40px);
		height: 1px;
		background: #e4e7ed;
		z-index: 1;
		transform: translateX(-20px);
	}
}
</style>
