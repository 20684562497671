<template>
	<svg
		class="svg-icon"
		:style="
			`${color ? 'color:' + color + ';' : ''}
        ${fontSize ? 'font-size:' + fontSize + ';' : ''}
        ${padding ? 'padding:' + padding + ';' : ''}
        ${margin ? 'margin:' + margin + ';' : ''}
        ${width ? 'width:' + width + ';' : ''}
        ${cursor ? 'cursor:' + cursor + ';' : ''}
        ${height ? 'height:' + height + ';' : ''}`
		"
		aria-hidden="true"
		v-on="$listeners"
	>
		<use :xlink:href="iconName" />
	</svg>
</template>

<script>
export default {
	name: 'SvgIcon',
	props: {
		//加载在assets/icons/svg里面的svg文件名 必须传
		iconClass: {
			type: String,
			required: true
		},
		//常用设置 目的是不用再为了简单的样式去设置class，减少项目复杂度
		//设置的颜色
		color: String,
		//设置的字体大小 同样可以改变大小
		fontSize: String,
		//设置的内边距
		padding: String,
		//设置的外边距
		margin: String,
		//设置的宽度
		width: String,
		//设置的高度
		height: String,
		//鼠标移上去的鼠标样式
		cursor: String
	},
	computed: {
		iconName() {
			return `#icon-${this.iconClass}`
		}
	}
}
</script>

<style lang="scss" scoped>
.svg-icon {
	width: 1em;
	height: 1em;
	fill: currentColor;
	overflow: hidden;
	vertical-align: -0.15em;
}

.svg-external-icon {
	background-color: currentColor;
	mask-size: cover !important;
	display: inline-block;
}
</style>
