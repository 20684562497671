<template>
	<div>
		<div v-for="item in dataList" :key="item.name">
			<el-submenu
				v-if="item.children && item.meta.isMenu && $store.state.permissList.includes(item.meta.permiss)"
				:index="item.path"
			>
				<template slot="title">
					<div class="menuIcon">
						<svg-icon :icon-class="item.meta.icon"></svg-icon>
					</div>
					<span slot="title">{{ item.meta.title }}</span>
				</template>
				<sideMenuItem :dataList="item.children" />
			</el-submenu>
			<el-menu-item
				v-else-if="item.meta.isMenu && $store.state.permissList.includes(item.meta.permiss)"
				:index="item.path"
			>
				<div class="menuIcon">
					<svg-icon :icon-class="item.meta.icon"></svg-icon>
				</div>
				<span slot="title">{{ item.meta.title }}</span>
			</el-menu-item>
		</div>
	</div>
</template>
<script>
import sideMenuItem from '@/components/sideMenuItem.vue'
import svgIcon from '@/components/svgIcon.vue'
export default {
	name: 'sideMenuItem',
	components: {
		sideMenuItem,
		svgIcon
	},
	props: {
		dataList: {
			type: Array,
			required: true
		}
	},
	data() {
		return {}
	},
	computed: {},
	mounted() {},
	methods: {}
}
</script>

<style lang="scss" scoped>
@import '../assets/css/global.scss';
.el-menu--collapse div .el-submenu__title span {
	height: 0;
	width: 0;
	overflow: hidden;
	visibility: hidden;
	display: inline-block;
}
//图标
.menuIcon {
	display: inline-block;
	font-size: 24px;
	margin: 5px;
	line-height: normal;
}
</style>
