import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router/index.js'
export default {
	// domId 不填-不刷新, 默认domId可填page
	get(url, params, headers = {}) {
		url = 'server' + url
		let sessionInfo = JSON.parse(localStorage.getItem('sessionInfo'))
		if (sessionInfo) {
			headers['orgCode'] = sessionInfo.orgInfo.code
			headers['access-token'] = sessionInfo.accessToken
		}
		return new Promise((res, rej) => {
			try {
				axios({
					method: 'get',
					url,
					params,
					headers
				}).then(data => {
					if (data.status == 200) {
						if (data.data.code) {
							if (data.data.code == 200) {
								res(data.data.result)
							} else if (data.data.code == 401) {
								localStorage.removeItem('sessionInfo')
								Message.closeAll()
								Message({
									type: 'error',
									message: data.data.msg || '请重新登录',
									customClass: 'heighest_zindex'
								})
								localStorage.removeItem('sessionInfo')
								router.push('/')
							} else if (data.data.code == 403) {
								Message.closeAll()
								Message({
									type: 'error',
									message: data.data.msg || '没有权限',
									customClass: 'heighest_zindex'
								})
							} else {
								Message.closeAll()
								Message({
									type: 'error',
									message: data.data.msg || '请求失败',
									customClass: 'heighest_zindex'
								})
								rej(data.data.result)
							}
						} else {
							Message.closeAll()
							Message({
								type: 'error',
								message: '请求异常',
								customClass: 'heighest_zindex'
							})
							res(data.data)
						}
					} else {
						rej(data)
					}
				})
			} catch (err) {
				rej(err)
			}
		})
	},
	getFile(url) {
		return new Promise((res, rej) => {
			try {
				axios({
					method: 'get',
					url: url,
					responseType: 'blob' // 注意返回的数据格式
				}).then(data => {
					if (data.status == 200) {
						res(data.data)
					} else {
						rej(data)
					}
				})
			} catch (err) {
				rej(err)
			}
		})
	},
	// domId 不填-不刷新, 默认domId可填page
	post(url, data, headers = {}) {
		url = 'server' + url
		let sessionInfo = JSON.parse(localStorage.getItem('sessionInfo'))
		if (sessionInfo) {
			headers['orgCode'] = sessionInfo.orgInfo.code
			headers['access-token'] = sessionInfo.accessToken
		}
		return new Promise((res, rej) => {
			try {
				axios({
					method: 'post',
					url,
					data,
					headers: headers
				}).then(data => {
					if (data.status == 200) {
						if (data.data.code) {
							if (data.data.code == 200) {
								res(data.data.result)
							} else if (data.data.code == 401) {
								localStorage.removeItem('sessionInfo')
								Message.closeAll()
								Message({
									type: 'error',
									message: data.data.msg || '请重新登录',
									customClass: 'heighest_zindex'
								})
								router.push('/')
							} else if (data.data.code == 403) {
								Message.closeAll()
								Message({
									type: 'error',
									message: data.data.msg || '没有权限',
									customClass: 'heighest_zindex'
								})
							} else {
								Message.closeAll()
								Message({
									type: 'error',
									message: data.data.msg || '请求失败',
									customClass: 'heighest_zindex'
								})
								rej(data.data.result)
							}
						} else {
							res(data.data)
						}
					} else {
						Message.closeAll()
						Message({
							type: 'error',
							message: '请求异常',
							customClass: 'heighest_zindex'
						})
						rej(data)
					}
				})
			} catch (err) {
				rej(err)
			}
		})
	},
	//文件下载
	download(url, data, headers = {}) {
		let sessionInfo = JSON.parse(localStorage.getItem('sessionInfo'))
		if (sessionInfo) {
			headers['orgCode'] = sessionInfo.orgInfo.code
			headers['access-token'] = sessionInfo.accessToken
		}
		url = 'server' + url
		return new Promise((res, rej) => {
			try {
				axios({
					method: 'post',
					url,
					data,
					headers,
					responseType: 'blob'
				}).then(res => {
					console.log(res)
					if (res.status == 200) {
						if (res.data) {
							var blob = new Blob([res.data])
							var url = window.URL.createObjectURL(blob)
							var aLink = document.createElement('a')
							aLink.style.display = 'none'
							aLink.href = url
							let contentDisposition = res.headers['content-disposition'] //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
							let patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
							let result = patt.exec(contentDisposition)
							let filename = decodeURI(result[1])
							aLink.setAttribute('download', filename)
							document.body.appendChild(aLink)
							aLink.click()
							document.body.removeChild(aLink) //下载完成移除元素
							window.URL.revokeObjectURL(url) //释放掉blob对象
						} else {
							Message({
								type: 'error',
								message: '请求异常'
							})
							rej(data)
						}
					}
				})
			} catch (err) {
				rej(err)
			}
		})
	},
	delete(url, data, headers = {}) {
		url = 'server' + url
		let sessionInfo = JSON.parse(localStorage.getItem('sessionInfo'))
		if (sessionInfo) {
			headers['orgCode'] = sessionInfo.orgInfo.code
			headers['access-token'] = sessionInfo.accessToken
		}
		return new Promise((res, rej) => {
			try {
				axios({
					method: 'delete',
					url,
					data,
					headers: headers
				}).then(data => {
					if (data.status == 200) {
						if (data.data.code) {
							if (data.data.code == 200) {
								res(data.data.result)
							} else if (data.data.code == 401) {
								localStorage.removeItem('sessionInfo')
								Message.closeAll()
								Message({
									type: 'error',
									message: data.data.msg || '请重新登录',
									customClass: 'heighest_zindex'
								})
								router.push('/')
							} else if (data.data.code == 403) {
								Message.closeAll()
								Message({
									type: 'error',
									message: data.data.msg || '没有权限',
									customClass: 'heighest_zindex'
								})
							} else {
								Message.closeAll()
								Message({
									type: 'error',
									message: data.data.msg || '请求失败',
									customClass: 'heighest_zindex'
								})
								rej(data.data.result)
							}
						} else {
							res(data.data)
						}
					} else {
						Message.closeAll()
						Message({
							type: 'error',
							message: '请求异常',
							customClass: 'heighest_zindex'
						})
						rej(data)
					}
				})
			} catch (err) {
				rej(err)
			}
		})
	}
}
