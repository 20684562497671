<template>
    <div class="tools_btn">
        <el-dropdown placement="bottom">
            <span class="el-dropdown-link">
                <svg-icon v-if="leftIcon" :icon-class="leftIcon"></svg-icon>
                <span>{{ content }}</span>
                <svg-icon v-if="rightIcon" :icon-class="rightIcon"></svg-icon>
            </span>
            <el-dropdown-menu class="tools_dropdown" slot="dropdown">
                <slot></slot>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
export default {
    name: 'ToolsDropdown',
    props: {
        leftIcon: String,
        rightIcon: String,
        content: String
    },
}
</script>

<style>
</style>