import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入全局inco
import '@/assets/icons'
//引入element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
//引入ajax
import ajax from '@/utils/axios.js'
Vue.prototype.ajax = ajax
//引入language
import language from '@/utils/language.js'
Vue.prototype.$t = language
//引入全局样式  设置主题为theme
import '@/assets/css/global.scss'
//引入常用方法
import '@/utils/common.js'
//引入常用组件
import commonHeader from './components/commonHeader'
Vue.component(commonHeader.name, commonHeader)
import toolsBtn from './components/toolsBtn'
Vue.component(toolsBtn.name, toolsBtn)
import toolsDropdown from './components/toolsDropdown'
Vue.component(toolsDropdown.name, toolsDropdown)
import glPage from './components/glPage'
Vue.component(glPage.name, glPage)
//引入模拟数据
import '@/utils/mock.js'
//先判断缓存是否存在权限有就拿来用
if (localStorage.getItem('permissList')) {
	store.commit('getPermissList', JSON.parse(localStorage.getItem('permissList')))
}
//全局路由前置守卫 过滤掉没有权限的路由  注意permiss为""的可以通过路由

router.beforeEach((to, from, next) => {
	if (to.meta.permiss && to.meta.permiss !== '' && store.state.permissList) {
		if (store.state.permissList.includes(to.meta.permiss)) {
			if (!to.meta.isFull) {
				store.commit('addPageTab', to)
				store.commit('setTabIndex', to.fullPath)
			}
			next()
		} else {
			// next({ path: '/403' })
			next()
		}
	} else next()
})

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
