<template>
    <div class="gl-page small">
        <el-pagination
            small
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page="currentPage"
            :page-sizes="sizesArr"
            :page-size="pageSize"
            background
            layout="total,sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
    </div>
</template>
<script>
export default {
    name: 'glPage',
    props: {
        pageSize: {//分页大小
            type: Number,
            required: true,
        },
        sizesArr: {//分页大小数组
            type: Array,
            default: () => [5, 10, 20, 30, 50, 100, 200],
        },
        currentPage: {//当前页数
            type: Number,
            required: true,
        },
        total: {//总的数据
            type: Number,
            required: true,
        },
    },
    methods: {
        currentChange(a) {
            this.$emit('current-change', a)
        },
        sizeChange(a) {
            this.$emit('size-change', a)
        },
    },
}
</script>
<style scoped>
.gl-page {
    margin: 20px 20px 0 0;
}
.gl-page.small {
    margin: 10px 0px 0 0;
}
.gl-page > div {
    float: right;
}
.gl-page::after {
    content: '';
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
}

.gl-page  ::v-deep .el-input--mini .el-input__inner {
    height: 22px;
    line-height: 22px;
}
.gl-page  ::v-deep .el-input--mini .el-input__icon {
    line-height: 22px;
}
</style>
