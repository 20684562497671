<template>
    <div class="tools_btn">
        <svg-icon v-if="leftIcon" :icon-class="leftIcon"></svg-icon>
        <slot></slot>
        <svg-icon v-if="rightIcon" :icon-class="rightIcon"></svg-icon>
    </div>
</template>

<script>
export default {
    name: 'ToolsBtn',
    props: {
        leftIcon: String,
        rightIcon: String,
    },
}
</script>

<style>
</style>