import Vue from 'vue'
import VueRouter from 'vue-router'
//嵌套路由父路由路径  展示嵌套子路由
// import About from '../views/About.vue'
Vue.use(VueRouter)
/*meta参数
 **title:标题名称 作用在tab上的名称
 **icon: icon 作用在侧边导航栏里面图标
 **keepAlive: 是否保持组件缓存
 **isMenu:是否显示在侧边导航栏里面
 **permiss:权限
 **isFull:是否全屏展示 不展示头部侧边导航栏及tab
 */
const routes = [
	{
		path: '/',
		name: '',
		redirect: '/login',
		meta: {
			title: '',
			icon: '',
			keepAlive: false,
			isMenu: false,
			permiss: '/',
			isFull: false
		}
	},
	{
		path: '/home',
		name: 'home',
		meta: {
			title: '首页',
			icon: 'caidan-1',
			keepAlive: false,
			isMenu: true,
			permiss: 'sysUserMange',
			isFull: false
		},
		component: () => import('../views/Home.vue')
	},
	{
		path: '/info',
		name: 'info',
		meta: {
			title: '系统管理',
			icon: 'setting',
			keepAlive: false,
			isMenu: true,
			permiss: 'sysUserMange',
			isFull: false
		},
		component: () => import('../views/About.vue'),
		children: [
			{
				path: '/user',
				name: 'user',
				meta: {
					title: '用户管理',
					icon: '',
					keepAlive: false,
					isMenu: true,
					permiss: 'sysUserMange',
					isFull: false
				},
				component: () => import('../views/user.vue')
			},
			{
				path: '/role',
				name: 'role',
				meta: {
					title: '角色管理',
					icon: '',
					keepAlive: false,
					isMenu: true,
					permiss: 'sysRoleMange',
					isFull: false
				},
				component: () => import('../views/role.vue')
			},
			{
				path: '/Applet',
				name: 'Applet',
				meta: {
					title: '小程序管理',
					icon: '',
					keepAlive: false,
					isMenu: true,
					permiss: 'sysUserMange',
					isFull: false
				},
				component: () => import('../views/Applet.vue')
			},
			{
				path: '/AppletDetails',
				name: 'AppletDetails',
				meta: {
					title: '小程序详情',
					icon: '',
					keepAlive: false,
					isMenu: false,
					permiss: 'sysUserMange',
					isFull: false
				},
				component: () => import('../views/AppletDetails.vue')
			},
			{
				path: '/AppletFill',
				name: 'AppletFill',
				meta: {
					title: '小程序配置',
					icon: '',
					keepAlive: false,
					isMenu: true,
					permiss: 'sysUserMange',
					isFull: false
				},
				component: () => import('../views/AppletFill.vue')
			},
			{
				path: '/banner',
				name: 'banner',
				meta: {
					title: '轮播图管理',
					icon: '',
					keepAlive: false,
					isMenu: true,
					permiss: 'sysUserMange',
					isFull: false
				},
				component: () => import('../views/banner.vue')
			},
			{
				path: '/scrollBar',
				name: 'scrollBar',
				meta: {
					title: '滚动广播管理',
					icon: '',
					keepAlive: false,
					isMenu: true,
					permiss: 'sysUserMange',
					isFull: false
				},
				component: () => import('../views/scrollBar.vue')
			}
		]
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			title: '登录',
			icon: 'home',
			keepAlive: false,
			isMenu: false,
			permiss: '',
			isFull: true
		},
		component: () => import('../views/login.vue')
	},
	// error-page
	{
		path: '/403',
		name: '403',
		meta: {
			title: '403',
			icon: '',
			keepAlive: false,
			isMenu: false,
			permiss: '',
			isFull: true
		},
		component: () => import('../views/errorPage/403.vue')
	},
	{
		path: '*',
		redirect: '/404',
		meta: {
			title: '',
			icon: '',
			keepAlive: false,
			isMenu: false,
			permiss: '',
			isFull: true
		}
	},
	{
		path: '/404',
		name: '404',
		meta: {
			title: '404',
			icon: '',
			keepAlive: false,
			isMenu: false,
			permiss: '',
			isFull: true
		},
		component: () => import('../views/errorPage/404.vue')
	}
]
export const menuRouter = routes.slice(0)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
