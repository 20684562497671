import Vue from 'vue'
import store from '@/store'
import { mapGetters } from 'vuex'

//算法 用于避免精度丢失
//加减  减法相当于加一个负值
function plus(n1, n2) {
	var s1, s2, m
	try {
		s1 = n1.toString().split('.')[1].length
	} catch (e) {
		s1 = 0
	}
	try {
		s2 = n2.toString().split('.')[1].length
	} catch (e) {
		s2 = 0
	}
	m = Math.pow(10, Math.max(s1, s2))
	return (n1 * m + n2 * m) / m
}
//乘
function ride(arg1, arg2) {
	if (arg1 == null || arg2 == null) {
		return null
	}
	var n1, n2
	var r1, r2 // 小数位数
	try {
		r1 = arg1.toString().split('.')[1].length
	} catch (e) {
		r1 = 0
	}
	try {
		r2 = arg2.toString().split('.')[1].length
	} catch (e) {
		r2 = 0
	}
	n1 = Number(arg1.toString().replace('.', ''))
	n2 = Number(arg2.toString().replace('.', ''))
	return (n1 * n2) / Math.pow(10, r1 + r2)
}
//除
function except(arg1, arg2) {
	if (arg1 == null) {
		return null
	}
	if (arg2 == null || arg2 == 0) {
		return null
	}
	var n1, n2
	var r1, r2 // 小数位数
	try {
		r1 = arg1.toString().split('.')[1].length
	} catch (e) {
		r1 = 0
	}
	try {
		r2 = arg2.toString().split('.')[1].length
	} catch (e) {
		r2 = 0
	}
	n1 = Number(arg1.toString().replace('.', ''))
	n2 = Number(arg2.toString().replace('.', ''))
	return (n1 / n2) * Math.pow(10, r2 - r1)
}

//时间日期 把时间戳还原成年月日等时间格式
function parseTime(time, cFormat) {
	if (arguments.length === 0) {
		return null
	}
	const format = cFormat || '{y}-{m}-{d}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if (('' + time).length === 10) time = parseInt(time) * 1000
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		o: date.getMonth(),
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{(y|m|d|h|i|s|a|o)+}/g, (result, key) => {
		let value = formatObj[key]
		if (key === 'a') {
			return ['日', '一', '二', '三', '四', '五', '六'][value]
		}
		if (key === 'o') {
			return ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'][
				value
			]
		}
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
	return time_str
}

//全局过滤选择器的枚举
function mapLabel(value, key) {
	// 显示公共enumLabel
	const data = store.state.enum
	if (key && data[key]) {
		return (
			data[key].find(e => e.value === value) || {
				label: value
			}
		).label
	} else return 'no enum'
}

Vue.prototype.plus = plus
Vue.prototype.ride = ride
Vue.prototype.except = except
Vue.prototype.parseTime = parseTime
Vue.filter('parseTime', parseTime)
Vue.filter('mapLabel', mapLabel)
//全局获取枚举对象
Vue.mixin({
	computed: {
		...mapGetters({
			$enum: 'getEnumData'
		})
	}
})
