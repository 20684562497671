<template>
	<div id="app">
		<el-container>
			<el-header v-if="!currentRoute.isFull" height="60px"><sideBar /></el-header>
			<el-container>
				<el-aside v-if="!currentRoute.isFull" :width="$store.state.showMenu ? '200px' : '64px'">
					<sideMenu />
				</el-aside>
				<el-container>
					<el-header v-if="!currentRoute.isFull" height="40px" class="bg"><headerTab /></el-header>
					<el-main style="padding:0;">
						<div
							class="page"
							:style="$store.state.showMenu ? 'width: calc(100vw - 200px)' : 'width: calc(100vw - 64px)'"
						>
							<keep-alive>
								<router-view v-if="currentRoute.keepAlive"></router-view>
							</keep-alive>
							<router-view v-if="!currentRoute.keepAlive"></router-view>
						</div>
					</el-main>
				</el-container>
			</el-container>
		</el-container>
	</div>
</template>
<script>
import sideBar from '@/components/sideBar.vue'
import sideMenu from '@/components/sideMenu.vue'
import headerTab from '@/components/headerTab.vue'
export default {
	components: {
		headerTab,
		sideMenu,
		sideBar
	},
	data() {
		return {}
	},
	created() {
		//缓存里面有枚举就获取枚举并设置全局
		if (localStorage.getItem('enumType')) {
			this.$store.commit('getEnum', JSON.parse(localStorage.getItem('enumType')))
		}
	},
	computed: {
		currentRoute() {
			return this.$route.meta
		}
	}
}
</script>
<style lang="scss">
@import './assets/css/global.scss';
* {
	margin: 0px;
	padding: 0px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	overflow: hidden;
	font-family: 'Avenir', Helvetica, Arial, sans-serif, 'Microsoft Yahei';
}
#app {
	height: 100%;
	width: 100%;
	font-family: 'Avenir', Helvetica, Arial, sans-serif, 'Microsoft Yahei';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.page {
	height: calc(100vh - 60px);
	@include bg_main_color(#111111);
	padding: 10px;
}
.main {
	@include main_bg_color(#111111);
	border-radius: 5px;
	padding: 20px 10px 20px 10px;
	box-sizing: border-box;
	height: 100%;
}
.el-aside {
	position: relative;
	height: 100%;
	overflow: visible !important;
	@include menu-bg-color-theme(#111111);
}
.el-container {
	height: 100%;
}
.el-main {
	background-color: #eff1f4;
}
.el-header {
	padding: 0 !important;
}
.bg {
	@include main_bg_color(#111111);
}
.full {
	width: 44px;
}
.notfull {
	width: 144px;
}
::v-deep .el-tabs__header {
	border: 0px;
}
</style>
