import cn from './cn.json'
function languageItem(cn,val){
    return  cn[val]
}
export default function language(val){
    if(typeof(val)=='string'){
        let str =''
        val.split('.').forEach((e,i) => {
            if(i==0){
                str=languageItem(cn,e)
            }else{
                str=languageItem(str,e)
            }
        })
        if(str!==undefined){
            if(typeof(str)=='string'){
                return str
            }else{
                return '返回的数据不是字符串格式，请检查路径！'
            }
        }else{
            return '请检查cn.js是否有该路径字符！'
        }
        
    }else{
        return "请输入字符串格式！"
    }
}