import Mock from 'mockjs'
let server = `server`
Mock.mock(server + '/api/data', () => {
	return Mock.mock({
		'list|20-40': [
			{
				id: '@increment(1)',
				title: '@ctitle',
				content: '@cparagraph',
				time: '@date(hh:mm:ss)',
				order: '@natural',
				name: '@cname',
				detail: '@county(true)',
				img: '@url',
				'money|1-10000': 1,
				'integral|1-10000': 1,
				wx: "@string('upper', 10)",
				date: '@date(yyyy-MM-dd)'
			}
		]
	})
})
