<template>
	<div class="sideMenu">
		<el-scrollbar>
			<el-menu
				:default-active="$store.state.tabIndex"
				class="el-menu-vertical-demo"
				:collapse="!$store.state.showMenu"
				:router="true"
			>
				<sideMenuItem :dataList="menuRouterList" />
			</el-menu>
		</el-scrollbar>
	</div>
</template>

<script>
import { menuRouter } from '@/router/index.js'
import sideMenuItem from '@/components/sideMenuItem.vue'
export default {
	name: 'sideMenu',
	components: {
		sideMenuItem
	},
	props: {},
	data() {
		return {}
	},
	computed: {
		menuRouterList() {
			return menuRouter || []
		}
	},
	mounted() {},
	methods: {}
}
</script>

<style lang="scss" scoped>
@import '../assets/css/global.scss';
.sideMenu {
	position: absolute;
	// @include menu_bg_color_theme(#111111);
	top: 0;
	bottom: 0;
	box-sizing: content-box;
	overflow: hidden;
	transition: 0.3s;
	z-index: 100;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
	.el-menu {
		border: none;
	}
}
// .el-menu {
// 	@include menu_bg_color_theme(#111111);
// }

// .el-menu::before {
// 	@include menu_bg_color_theme(#111111);
// }

// .el-submenu ::v-deep .el-submenu__title {
// 	height: 50px;
// 	line-height: 50px;
// 	@include menu_bg_color_theme(#111111);
// }
// ::v-deep .el-menu-item{
// 	@include s_font_color(#ffffff);
// 	@include menu_bg_color_theme(#111111);
// }
// ::v-deep .el-submenu {
// 	@include s_font_color(#ffffff);
// }

.el-menu-vertical-demo:not(.el-menu--collapse) {
	width: 200px;
	min-height: 400px;
}

::v-deep .el-menu--collapse .el-submenu__icon-arrow {
	display: none;
}
//侧边栏滚动条
::v-deep .el-scrollbar {
	height: 100%;
}
::v-deep .el-scrollbar__wrap {
	overflow-x: hidden;
}
</style>
